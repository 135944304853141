.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: #000000;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

@keyframes floatBall1 {
  0% {
    transform: translate(0, 0) scale(1);
  }
  33% {
    transform: translate(30vw, 20vh) scale(1.2);
  }
  66% {
    transform: translate(-20vw, 40vh) scale(0.8);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes floatBall2 {
  0% {
    transform: translate(0, 0) scale(0.8);
  }
  33% {
    transform: translate(-25vw, -30vh) scale(1);
  }
  66% {
    transform: translate(35vw, 25vh) scale(1.2);
  }
  100% {
    transform: translate(0, 0) scale(0.8);
  }
}

@keyframes floatBall3 {
  0% {
    transform: translate(0, 0) scale(1.2);
  }
  33% {
    transform: translate(40vw, -20vh) scale(0.8);
  }
  66% {
    transform: translate(-30vw, -40vh) scale(1);
  }
  100% {
    transform: translate(0, 0) scale(1.2);
  }
}

.floating-ball {
  position: absolute;
  border-radius: 50%;
  filter: blur(40px);
  opacity: 0.8;
  z-index: 0;
  mix-blend-mode: screen;
}

.ball-1 {
  width: 600px;
  height: 600px;
  background: #4a90e2;
  animation: floatBall1 20s infinite ease-in-out;
  top: 30%;
  left: 25%;
}

.ball-2 {
  width: 700px;
  height: 700px;
  background: #7b68ee;
  animation: floatBall2 25s infinite ease-in-out;
  top: 40%;
  right: 25%;
}

.ball-3 {
  width: 650px;
  height: 650px;
  background: #6a5acd;
  animation: floatBall3 22s infinite ease-in-out;
  bottom: 30%;
  left: 35%;
}

.ball-4 {
  width: 550px;
  height: 550px;
  background: #483d8b;
  animation: floatBall4 28s infinite ease-in-out;
  top: 15%;
  right: 20%;
}

.ball-5 {
  width: 500px;
  height: 500px;
  background: #9370db;
  animation: floatBall5 32s infinite ease-in-out;
  bottom: 25%;
  left: 15%;
}

.ball-6 {
  width: 650px;
  height: 650px;
  background: #8a2be2;
  animation: floatBall6 35s infinite ease-in-out;
  top: 45%;
  left: 10%;
}

.ball-7 {
  width: 480px;
  height: 480px;
  background: #4169e1;
  animation: floatBall7 27s infinite ease-in-out;
  bottom: 35%;
  right: 15%;
}

.ball-8 {
  width: 520px;
  height: 520px;
  background: #6495ed;
  animation: floatBall8 31s infinite ease-in-out;
  top: 25%;
  left: 30%;
}

.ball-9 {
  width: 580px;
  height: 580px;
  background: #9400d3;
  animation: floatBall9 29s infinite ease-in-out;
  bottom: 20%;
  right: 25%;
}

.ball-10 {
  width: 620px;
  height: 620px;
  background: #8b4513;
  animation: floatBall10 33s infinite ease-in-out;
  top: 35%;
  right: 30%;
}

.App-header {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(8px + 1.5vmin);
  color: white;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

h1 {
  margin: 0;
  padding: 0;
  margin-top: 15vh;
  margin-bottom: 2vh;
  font-size: 2.2em;
}

h2 {
  margin: 0;
  padding: 0;
  margin-bottom: 8vh;
  font-size: 1.3em;
  font-weight: 400;
  opacity: 0.9;
}

.weather-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  width: 100%;
  max-width: 1400px;
  padding: 30px 60px;
  margin-bottom: 120px;
}

.weather-card {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;
  font-family: "Roboto", sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.weather-card:hover:not(.selected) {
  transform: scale(1.05);
  background: rgba(0, 0, 0, 0.4);
}

.card-content {
  text-align: left;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.date {
  font-weight: 500;
  font-size: 0.9em;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
}

.temperature,
.condition,
.wind,
.rain-chance {
  margin: 0;
  font-size: 0.8em;
  color: #fff;
  font-weight: 400;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1.4;
}

.temperature span,
.condition span,
.wind span,
.rain-chance span {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.progress-bar {
  width: 80px;
  height: 80px;
}

@media screen and (max-width: 1200px) {
  .day-container {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 768px) {
  .weather-cards,
  .hourly-cards {
    padding: 30px 40px;
    gap: 30px;
    width: calc(100% - 80px);
  }

  .day-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .weather-card {
    width: 100%;
    max-width: 400px;
  }

  .hourly-cards {
    padding: 0 20px;
  }

  .App-header {
    padding: 20px;
  }

  h1 {
    font-size: 1.8rem;
    padding: 0 20px;
    margin-top: 20px;
  }

  h2 {
    font-size: 1.2rem;
    padding: 0 20px;
  }

  .card-content {
    font-size: 0.9em;
    width: 100%;
  }

  .progress-bar {
    width: 60px;
    height: 60px;
  }

  .weather-cards {
    margin-bottom: 140px;
  }

  .App-footer {
    padding: 15px;
    font-size: 0.85em;
  }
}

@media screen and (max-width: 380px) {
  .weather-cards,
  .hourly-cards {
    padding: 20px 30px;
    gap: 20px;
    width: calc(100% - 60px);
  }

  .day-container {
    width: 100%;
  }

  h1 {
    font-size: 1.3rem;
  }

  .weather-card {
    padding: 20px;
  }
}

.day-container {
  width: calc(33.333% - 20px);
  min-width: 280px;
  transition: all 0.3s ease;
  position: relative;
}

.expanded-view .day-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weather-card.selected {
  opacity: 1;
  pointer-events: auto;
}

.weather-card.hidden {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
}

.weather-card.hourly {
  transform: none;
  width: 100%;
  max-width: 400px;
}

.hourly-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
  width: 100%;
  max-width: 1400px;
  padding: 30px 60px;
  margin-top: 30px;
  margin-bottom: 120px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.expanded-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9em;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 2;
}

/* Theme toggle button */
.theme-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: all 0.3s ease;
  display: none;
}

.theme-toggle:hover {
  transform: scale(1.1);
}

/* Dark theme (default) */
.dark-theme {
  background: #000000;
}

.dark-theme .App-header {
  background: rgba(0, 0, 0, 0.4);
  color: white;
}

.dark-theme .weather-card {
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

/* Light theme */
.light-theme {
  background: #f5f5f1;
}

.light-theme .App-header {
  background: rgba(245, 245, 241, 0.4);
  color: #1a1d24;
}

.light-theme .weather-card {
  background: rgba(245, 245, 241, 0.3);
  color: #1a1d24;
}

.light-theme .temperature,
.light-theme .condition,
.light-theme .wind,
.light-theme .rain-chance,
.light-theme .date {
  color: #1a1d24;
  text-shadow: none;
}

.light-theme .temperature span,
.light-theme .condition span,
.light-theme .wind span,
.light-theme .rain-chance span {
  color: rgba(26, 29, 36, 0.8);
}

.light-theme .App-footer {
  background: rgba(245, 245, 241, 0.3);
  color: #1a1d24;
}

.light-theme .floating-ball {
  opacity: 0.8;
  mix-blend-mode: screen;
}
